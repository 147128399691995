<template>
    <v-dialog v-model="dialog"  max-width="450" scrollable>
        <v-card >
            <v-toolbar class="modal-header">
                <v-toolbar-title>About Sig2</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="card-text-con">
                <div class="about-card-text">
                    Sig2  Version 1.5 <br/><br/>
                    Sig2 is a communication utility designed and engineered by a team
                    distributed across 4 countries.<br/><br/>
                    Kai Brunner, Nik Edmiidz, Kishor Kumar<br/><br/>
                    <a class="nav-link" target="_blank" href="https://sig2labs.com">www.sig2labs.com</a><br/>
                    <a class="nav-link" href="mailto:info@sig2labs.com">Contact</a>
                </div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <button @click="close" class="close-btn text-btn btn ms-auto">Close</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script >
import { ref, defineEmits } from 'vue';
import { VDialog, VCard,VToolbarTitle,VToolbar, VCardText, VCardActions } from 'vuetify'

export default {
    name: 'AboutModal',
    setup(){
        const imit = defineEmits(['closed'])
    },
    components : {
        VDialog, VCard, VCardText, VToolbar, VToolbarTitle, VCardActions  
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('closed', value);
            }
        }
    },
    methods: {
        close() {
            this.dialog = false;
        }
    }
};
</script>
<style lang="scss" >
   .card-text-con{
        padding: 25px 10px 0 16px !important;
        .about-card-text{
            color: #000;
            font-family: 'Roboto';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.286%;
            .nav-link{
                color: #344D7A;
                font-size: 12px;
                font-weight: 500;
                line-height: 16px;
            }
        }
   }
   
   .v-toolbar-title .v-toolbar-title__placeholder{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
   }
</style>