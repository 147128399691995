<template>
   <div class="c-card-group">
      <div class="c-card">
        <div class="c-card-item">
          <div class="c-card-title">Usage</div>
          <div class="c-card-content">
             <div class="c-content-row row-fill-3">
                <div>Admin</div>
                <div>Managing Operators</div>
                <div class="c-value">1</div>
             </div>
             <div class="c-content-row row-fill-3">
                <div>Operators</div>
                <div>Managing contacts; Setting geofence messages</div>
                <div class="c-value">1</div>
             </div> 
             <div class="c-content-row row-fill-3">
                <div>Receivers</div>
                <div>Hearing geofence messages in the field</div>
                <div class="c-value">20</div>
             </div>  
             <div class="c-bottom-content">
                <div class="c-content-row row-fill-3-1">
                  <div>Geofence Messages</div>
                  <div class="d-flex align-items-center col-span-2">
                      <div style="margin-top: 20px;" class="a-slider account-slider" :class="{ flip: difference, unflip: !difference }"> 
                        <v-slider
                            min="0"
                            max="100"
                            :step="1"
                            strict
                            @start="onSliderDragStart"
                            @end="onSliderDragEnd"
                            show-ticks="always"
                            thumb-label="always"
                            thumb-size="16"
                            tick-size="0"
                            track-color="#BDBDBD"
                            track-fill-color="#828282"
                        >
                        </v-slider>
                    </div>
                    </div>
              </div>
             </div>
          </div>
        </div>
      </div>

      <div class="c-card card-right-full">
        <div class="c-card-item">
          <div class="c-card-title">Monthly Rate</div>
          <div class="c-card-content">
             <div class="c-content-row">
                <div>$5.90 per Admin</div>
                <div class="c-value">$5.90</div>
             </div>
             <div class="c-content-row">
                <div>$3.90 per Operator</div>
                <div class="c-value">$3.90</div>
             </div> 
             <div class="c-content-row">
                <div>$0.35 per Receiver</div>
                <div class="c-value">$7.00</div>
             </div> 
             <div class="c-content-row">
                <div>$0.15 per Message</div>
                <div class="c-value">$15.00</div>
             </div>
             <div class="c-bottom-content">
              <div class="c-content-row">
                <div></div>
                <div class="c-value">$0</div>
             </div>
             <div class="c-content-row">
                <div></div>
                <div class="c-value">$0</div>
             </div>
             <div class="c-content-row">
                <div></div>
                <div class="c-value">$0</div>
             </div>
            </div>
          </div>
        </div>
      </div>

      <div class="c-card">
        <div class="c-card-item">
          <div class="c-card-title">Add-ons</div>
          <div class="c-card-content">
             <div class="c-content-row row-fill-3-2">
                <div>Activity Report</div>
                <div></div>
                <div class="c-switch-row">
                  <div class="c-icon-text">
                    <IconQuestionMark />
                    <div>Real time updates</div>
                  </div>
                  <SwitchLg v-model="switch1" />
                </div>
             </div>
             <div class="c-content-row row-fill-3-2">
                <div>Activity Report</div>
                <div class="c-switch-row">
                  <div class="c-icon-text">
                    <IconQuestionMark />
                    <div>European languages</div>
                  </div>
                  <SwitchLg v-model="switch5" />
                </div>
                <div class="c-switch-row">
                  <div class="c-icon-text">
                    <IconQuestionMark />
                    <div>World languages</div>
                  </div>
                  <SwitchLg v-model="switch2" />
                </div>
             </div> 
             <div class="c-bottom-content">
              <div class="c-content-row row-fill-3-2">
                  <div>Analytics</div>
                  <div class="c-switch-row">
                    <div class="c-icon-text">
                      <div>Operator View</div>
                    </div>
                    <SwitchLg v-model="switch3" />
                  </div>
                  <div class="c-switch-row">
                    <div class="c-icon-text">
                      <div>Global View</div>
                    </div>
                    <SwitchLg v-model="switch4" />
                  </div>
              </div> 
            </div>
          </div>
        </div>
      </div>

      <div class="c-card-footer">
        <h6>Monthly Total</h6>
        <div class="c-value">$5.90</div>
      </div>
       
 
   </div>

    
</template>

<script setup>
import SwitchLg from '@/components/common/SwitchLg.vue';
import IconQuestionMark from '@/components/icons/IconQuestionMark.vue';

import { ref } from 'vue';

const switch1 = ref(true);
const switch2 = ref(false);
const switch3 = ref(false);
const switch4 = ref(true);
const switch5 = ref(true);

function onSliderDragStart(){
            return false;
}
function onSliderDragEnd(){
    
    //will be used later
}
</script>

<style lang="scss" scoped>
 .c-card-group {
    display: grid;
    grid-template-columns: 1fr 440px;
    column-gap: 60px;
    row-gap: 50px;
    margin-bottom: 60px;
 }

 .c-card-column-3 {
    display: grid;
    grid-template-columns: 266fr 272fr 202fr;
 }

 .c-card-column-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
 }

 .card-right-full {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
 }

 .content-type-1 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    & .btn-group {
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        gap: 22px;
        height: 85px;
    }
  }

  .c-content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: 60px;
    border-bottom: 1px solid #E0E0E0;
    line-height: 20px;
    &.row-fill-3 {
      display: grid;
      grid-template-columns: 235px 370px auto;
      justify-content: unset;
    }
    &.row-fill-3-1 {
      display: grid;
      grid-template-columns: 204px auto;
      justify-content: unset;
      height: 80px;
    }
    &.row-fill-3-2 {
      display: grid;
      grid-template-columns: 201px 260px auto;
      justify-content: space-between;
    }
    & .c-value {
      font-size: 24px;
      line-height: 20px;
      text-align: right;
    }
  }

  .c-bottom-content {
    margin-top: auto;
    & .c-content-row:last-child {
      border-bottom: none;
    }
  }

  .switch-group {
    margin-top: 27px;
  }

 .c-card {
    background-color: #fff;
    padding: 33px 30px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    & .c-card-title {
        font-size: 16px;
        line-height: 16px;
        color: #000;
        padding-bottom: 11px;
        border-bottom: 1px solid #E0E0E0;
        font-weight: 500;
    }
    & .c-card-item {
      display: flex;
      flex-direction: column;
      flex: 1;
        & .c-card-content {
          display: flex;
          flex-direction: column;
          flex: 1;
        & ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
 }

 
 
 .c-switch-row {
    display: grid;
    text-align: right;
    grid-template-columns: 158px 46px;
    white-space: nowrap;
    gap: 30px;
    & .c-icon-text {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
    }
 }

 .c-card-footer {
    grid-column-start: 1;
    grid-column-end: 3;
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .h6 {
      font-size: 16px;
      line-height: 1;
      font-weight: 500;
    }
    & .c-value {
      font-size: 24px;
      line-height: 20px;
      text-align: right;
    }
 }
  
</style>
