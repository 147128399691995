<template>
  <div 
    class="switch" 
    @click="toggleSwitch" 
    @mouseover="isHovered = true" 
    @mouseleave="isHovered = false"
  >
    <img 
      width="30"
      height="30"
      :src="currentIcon" 
      :alt="modelValue ? 'Switch On' : 'Switch Off'"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';
import SwitchOn from '@/assets/images/icons/switch-on-sm.svg';
import SwitchOff from '@/assets/images/icons/switch-off-sm.svg';
import SwitchOnHover from '@/assets/images/icons/switch-on-sm-hover.svg';
import SwitchOffHover from '@/assets/images/icons/switch-off-sm-hover.svg';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

const isActive = ref(props.modelValue);
const isHovered = ref(false);

watch(() => props.modelValue, (newVal) => {
  isActive.value = newVal;
});

const currentIcon = computed(() => {
  if (isActive.value) {
    return isHovered.value ? SwitchOnHover : SwitchOn;
  } else {
    return isHovered.value ? SwitchOffHover : SwitchOff;
  }
});

const toggleSwitch = () => {
  isActive.value = !isActive.value;
  emit('update:modelValue', isActive.value);
};
</script>

<style scoped>
.switch {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
