<template>
  <div class="hovered-btn">
    <svg class="default" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#B1B9C1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.81738 6.75002C7.18146 5.71506 8.23892 5.09358 9.32027 5.27906C10.4016 5.46454 11.1915 6.40289 11.1899 7.50002C11.1899 9.00002 8.93988 9.75002 8.93988 9.75002" stroke="#7B8794" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="9.15" cy="12.9" r="0.9" fill="#7B8794" stroke="#7B8794" stroke-width="0.75"/>
    </svg>

    <svg class="hovered" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" fill="#7B8794" stroke="#7B8794" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.81738 6.75002C7.18146 5.71506 8.23892 5.09358 9.32027 5.27906C10.4016 5.46454 11.1915 6.40289 11.1899 7.50002C11.1899 9.00002 8.93988 9.75002 8.93988 9.75002" stroke="white" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
      <circle cx="9.15" cy="12.9" r="0.9" fill="white" stroke="white" stroke-width="0.75"/>
    </svg>

  </div>
</template>